import React from 'react'
// import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import theme from '../constants/theme'

const Wrapper = styled.div`
  height: ${props => props.height}px;
  margin-bottom: 0;

  @media (min-width: ${theme.breakpoints.md}) {
    height: ${props => `${props.height * 1.5}px`};
  }
`

const StyledBackground = styled(Img)`
  padding: 0;
  height: 100%;
`
const StyledText = styled.div`
  position: absolute;
  width: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  top: 2em;
  padding: 0 1em;

  h1 {
    font-size: 1.8em;
  }
  p {
    font-size: 1em;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    top: 3em;
    h1 {
      font-size: 2.2em;
    }
    p {
      font-size: 1.3em;
    }
  }
  @media (min-width: ${theme.breakpoints.md}) {
    top: 6.5em;
  }
`

const JumboHeader = props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "reine_winter_1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1800, maxHeight: 500, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper
        height={props.height}
        className="jumbotron jumbotron-billboard text-center mt-3 p-0 shadow border border-dark"
      >
        <StyledBackground fluid={data.backgroundImage.childImageSharp.fluid} />
        <StyledText>
          <div className="container">{props.children}</div>
        </StyledText>
      </Wrapper>
    )}
  />
)

JumboHeader.propTypes = {}

export default JumboHeader
