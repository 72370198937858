import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 3em;
  margin: 0;
  h2 {
    font-size: 1.8em;
  }
  p {
    font-size: 1.2em;
  }
  p:last-child {
    margin: 0;
  }
`

const ContactCard = props => {
  return (
    <Wrapper
      className="jumbotron text-white text-center rounded px-0 shadow border border-dark"
      id="contact-sign"
    >
      <div className="col-md-12 px-3">
        <h2 className="text-dark contact-text">
          For questions or to book a cabin, please contact:
        </h2>
        <p className="text-dark contact-text">
          Phone:
          <br />
          <a href="tel:+47 902 84 137">+47 902 84 137</a>
          <br />
          Email:
          <br />
          <a href="mailto:moeller.rorbu@hotmail.com">
            moeller.rorbu@hotmail.com
          </a>
        </p>
        <p className="text-dark contact-text">Welcome to Lofoten!</p>
      </div>
    </Wrapper>
  )
}

export default ContactCard
