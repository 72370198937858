import React from 'react'
// import { Link } from 'gatsby'
import ContactCard from '../components/ContactCard'
import JumboHeader from '../components/JumboHeader'
import styled from 'styled-components'
import CabinImageCarousel from '../components/CabinImageCarousel'
import { graphql } from 'gatsby'

import theme from '../constants/theme'
import Layout from '../components/Layout'

const StyledCabinRow = styled.div`
  margin: 1em 0;
  text-align: center;
  @media (min-width: ${theme.breakpoints.md}) {
    text-align: left;
  }
`

const CabinHeaderText = styled.h2`
  text-align: center;
  padding-bottom: 0.2em;
`

const ListItem = styled.li`
  padding: 0.3em 0;
`

const List = styled.ul`
  list-style-type: circle;
  list-style-position: inside;
`

const TextClasses = 'col-12 col-md-6 px-0 px-md-3'

const ImageCarouselClasses = 'col-12 col-md-6 px-0 px-md-3'

const CabinsPage = ({ data }) => {
  return (
    <Layout>
      <JumboHeader height={150}>
        <h1>Cabins</h1>
      </JumboHeader>
      <div className="container text-center py-3">
        <h5 className="card-text my-0">
          Enjoy a relaxing stay at one of our cabins.
        </h5>
        <h5 className="card-text my-0">
          They all have a balcony with a lovely view of the sea and mountains.
        </h5>
        <h5 className="card-text my-0">Suitable for families and groups.</h5>
        <p className="card-text mb-2">
          • Electricity • Water • Balcony • View of the Lofoten Mountains •
          Parking •
        </p>
      </div>

      {/* HYTTA */}
      <StyledCabinRow className="row">
        <div className={TextClasses}>
          <CabinHeaderText>Hytta</CabinHeaderText>
          <p className="text-muted text-center">Suitable for 3 people.</p>
          <List className="p-0">
            <ListItem>
              Bedroom with double bed and single bunk bed above.
            </ListItem>
            <ListItem>Bathroom with toilet and shower.</ListItem>
            <ListItem>Kitchen with electric cooking hob.</ListItem>
            <ListItem>Water and electricity.</ListItem>
            <ListItem>Balcony.</ListItem>
          </List>
        </div>
        <div className={ImageCarouselClasses}>
          <CabinImageCarousel images={data.hyttaImages.nodes} />
        </div>
      </StyledCabinRow>
      <hr />
      {/* GAMMELBUA */}
      <StyledCabinRow className="row">
        <div className={TextClasses}>
          <CabinHeaderText>Gammelbua</CabinHeaderText>
          <p className="text-muted text-center">Suitable for 5 people.</p>
          <List className="p-0">
            <ListItem>
              Bedroom with double bed and single bunk bed above and double
              sleeping couch in living room.
            </ListItem>
            <ListItem>Bathroom with toilet and shower.</ListItem>
            <ListItem>Kitchen with electric cooking hob.</ListItem>
            <ListItem>Water and electricity.</ListItem>
            <ListItem>Balcony.</ListItem>
          </List>
        </div>
        <div className={ImageCarouselClasses}>
          <CabinImageCarousel images={data.gammelbuaImages.nodes} />
        </div>
      </StyledCabinRow>
      <hr />
      {/* SVALA */}
      <StyledCabinRow className="row">
        <div className={TextClasses}>
          <CabinHeaderText>Svala</CabinHeaderText>
          <p className="text-muted text-center">Suitable for 5 people.</p>
          <List className="p-0">
            <ListItem>
              Bedroom with double bed and loft with three single beds.
            </ListItem>
            <ListItem>Bathroom with toilet and shower.</ListItem>
            <ListItem>Kitchen with electric cooking hob.</ListItem>
            <ListItem>Water and electricity.</ListItem>
            <ListItem>Balcony.</ListItem>
          </List>
        </div>
        <div className={ImageCarouselClasses}>
          <CabinImageCarousel images={data.svalaImages.nodes} />
        </div>
      </StyledCabinRow>
      <hr />
      {/* ØVERFJØSEN */}
      <StyledCabinRow className="row">
        <div className={TextClasses}>
          <CabinHeaderText>Øverfjøsen</CabinHeaderText>
          <p className="text-muted text-center">Suitable for 5 people.</p>
          <List className="p-0">
            <ListItem>
              Loft with three single beds and bunk bed in ListItemving room.
            </ListItem>
            <ListItem>Bathroom with toilet and shower.</ListItem>
            <ListItem>Kitchen with electric cooking hob.</ListItem>
            <ListItem>Water and electricity.</ListItem>
            <ListItem>Balcony.</ListItem>
            <ListItem>Parking.</ListItem>
          </List>
        </div>
        <div className={ImageCarouselClasses}>
          <CabinImageCarousel images={data.overfjosenImages.nodes} />
        </div>
      </StyledCabinRow>
      <hr />
      {/* NERFJØSEN */}
      <StyledCabinRow className="row">
        <div className={TextClasses}>
          <CabinHeaderText>Nerfjøsen</CabinHeaderText>
          <p className="text-muted text-center">Suitable for 5 people.</p>
          <List className="p-0">
            <ListItem>
              Bedroom with double bed and loft with three single beds.
            </ListItem>
            <ListItem>Bathroom with toilet and shower.</ListItem>
            <ListItem>Kitchen with electric cooking hob.</ListItem>
            <ListItem>Water and electricity.</ListItem>
            <ListItem>Balcony.</ListItem>
            <ListItem>Parking.</ListItem>
          </List>
        </div>
        <div className={ImageCarouselClasses}>
          <CabinImageCarousel images={data.nerfjosenImages.nodes} />
        </div>
      </StyledCabinRow>
      <hr />
      <ContactCard />
    </Layout>
  )
}

export default CabinsPage

export const imgFragment = graphql`
  fragment imgFragment on File {
    childImageSharp {
      fluid(maxHeight: 800, maxWidth: 700, quality: 50) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    hyttaImages: allFile(filter: { absolutePath: { regex: "/hytta/" } }) {
      nodes {
        id
        ...imgFragment
      }
    }
    gammelbuaImages: allFile(
      filter: { absolutePath: { regex: "/gammelbua/" } }
    ) {
      nodes {
        id
        ...imgFragment
      }
    }
    svalaImages: allFile(filter: { absolutePath: { regex: "/svala/" } }) {
      nodes {
        id
        ...imgFragment
      }
    }
    overfjosenImages: allFile(
      filter: { absolutePath: { regex: "/overfjosen/" } }
    ) {
      nodes {
        id
        ...imgFragment
      }
    }
    nerfjosenImages: allFile(
      filter: { absolutePath: { regex: "/nerfjosen/" } }
    ) {
      nodes {
        id
        ...imgFragment
      }
    }
  }
`
